import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import ImageText from '../components/ImageText/ImageText';
import TextBox from '../components/TextBox/TextBox';
import Square from '../components/Square/Square';
import Footer from '../components/Footer/Footer';

import classes from '../Stylesheets/careers.module.css';
import landing from '../images/careers-landing.jpg';
import smallpic from '../images/careers-landing-sm.jpg';
import box1 from '../images/careers-box1.jpg';
import box2 from '../images/careers-box2.jpg';
import picture2 from '../images/careers-picture2.jpg';

const Careers = () => (
		<section className="parent">
		<Jumbotron image={landing} preimage={smallpic} title="Careers"/>
			<TextBox
				header="Company Culture"
			content="At ACD, we strive to keep our vision of “creating and sustaining housing opportunities for everyone” in everyone’s sight because we know if we’re all pulling in the same direction, we can bring our vision to fruition.  Along with an emphasis on teamwork, we’ve cultivated an environment that promotes learning and employee growth.  We don’t want employees to feel stuck in their roles, but rather to empower employees to explore their talents and create a role unique and fulfilling to them – as long as it brings us a step closer to creating housing for everyone! "
			/>
			<section className={classes.imageText}>
				<section className={classes.picture1}>
					<h2>Perks and Benefits</h2>
				</section>
				<section className={classes.paragraph1}>
					<h2>Perks and Benefits</h2>
					<ul>
						<li>Family Owned</li>
						<li>Friendly Team Atmosphere </li>
						<li>Performance Bonuses</li>
						<li>Vacation/Paid Time Off</li>
						<li>Paid Sick Days</li>
						<li>Complimentary Coffee & Soft Drinks</li>
						<li>Spacious Offices</li>
						<li>Covered Parking</li>
						<li>Company Gym</li>
						<li>Designated Indoor & Outdoor Lunch Areas</li>
						<li>Dental and Medical Coverage</li>
						<li>Life Insurance Policy</li>
						<li>401(k) with Employer Match</li>
					</ul>
				</section>
			</section>
		<ImageText
				opposite
				image={picture2}
				header="Staff Testimonials"
				content="“ACD has exceptional facilities and work life balance.  The company has a family atmosphere that simultaneously keeps the work environment professional but relaxed.  On top of that, I love that the work I do day-to-day creates housing for people who need it and makes a difference in the world.  One of the best work environments I have experienced!”

					-Mark T."

			/>
			
		<section className="squares">
			<Square image={box1} title="Openings" anchor="/contact" action="Contact Us" />
			<Square image={box2} title="Meet the Team" anchor="/team" />
		</section>
		<Footer />
	</section>

);

export default Careers;