import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import TeamMember from '../components/TeamMember/TeamMember';
import BioBox from '../components/BioBox/BioBox';
import Footer from '../components/Footer/Footer';

import landing from '../images/team-landing.jpg';
import smallpic from '../images/team-landing-sm.jpg';


import classes from '../Stylesheets/team.module.css';
import { PeopleEntity } from '../App';
import { withRouter, RouteComponentProps } from 'react-router';


interface IProps extends RouteComponentProps{
	peopleEntities?: PeopleEntity[] | null;
}

class Team extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
	}

	scrollAnchorRef: React.RefObject<HTMLDivElement> = React.createRef();

	clickedPersonHandler = (object: PeopleEntity) => {
		this.props.history.push({
			state: { isBioOpen: true, clickedPerson:object}
		})
		const anchor = this.scrollAnchorRef;
		if (anchor.current) {
			anchor.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
		}
		else {
			return
		}
	}

	closeHandler = () => {
		this.props.history.push({ state: { isBioOpen: false } })
	}


	render() {
		if (this.props.peopleEntities === undefined || this.props.peopleEntities === null) {
			return <div>Loading...</div>
		}
		return (
			<React.Fragment>
				{this.props.location.state !== undefined && this.props.location.state.isBioOpen ?
					<section className="parent">
						<Jumbotron image={landing} preimage={smallpic} title="Meet the Team" className={classes.jumbotron} />
						<section className={classes.mainText}>
							<h1>Working Together with Excellence</h1>
							<p>"Teamwork is the ability to work together toward a common vision.  The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results." – Andrew Carnegie
							</p>
						</section>
						<div ref={this.scrollAnchorRef} className="parent">
							<BioBox name={this.props.history.location.state.clickedPerson.Name} position={this.props.history.location.state.clickedPerson.Title}
								bio={this.props.history.location.state.clickedPerson.Description} photo={this.props.history.location.state.clickedPerson.ImageZoom} clicked={() => this.closeHandler()} />
						</div>
						<section className={classes.wrapper}>
							{this.props.peopleEntities.map((member, index) => {
								return <TeamMember name={member.Name} position={member.Title} photo={member.ImageSprite} clicked={() => this.clickedPersonHandler(member)} key={index} />
							})}
						</section>
						<Footer />
					</section>
					:
					<section className="parent">
						<Jumbotron image={landing} preimage={smallpic} title="Meet the Team" className={classes.jumbotron} />
						<section className={classes.mainText}>
							<h1>Working Together with Excellence</h1>
							<p>"Teamwork is the ability to work together toward a common vision.  The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results." – Andrew Carnegie
							</p>
						</section>
						<div ref={this.scrollAnchorRef} className="parent">
						</div>
						<section className={classes.wrapper}>
							{this.props.peopleEntities.map((member, index) => {
								return <TeamMember name={member.Name} position={member.Title} photo={member.ImageSprite} clicked={() => this.clickedPersonHandler(member)} key={index} />
							})}
						</section>
						<Footer />
					</section>
					}
				</React.Fragment>
		);
	};
};

export default withRouter(Team);