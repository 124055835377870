import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import Property from '../components/Property/Property';
import PropertyInfo from '../components/PropertyInfo/PropertyInfo';
import Footer from '../components/Footer/Footer';

//import TextBox from '../components/TextBox/TextBox';
import landing from '../images/properties-landing.jpg';
import smallpic from '../images/properties-landing-sm.jpg';

import classes from '../Stylesheets/properties.module.css';
//import bayviewTower from '../images/PropertyPhotos/BayViewTower/Front.jpg';
//import crystalLofts from '../images/PropertyPhotos/CrystalLofts/LeftAngle.jpg';
//import freedomPlace from  '../images/PropertyPhotos/FreedomPlace/FrontView.jpg';
//import himelhoch from '../images/PropertyPhotos/Himelhoch/frontView.jpg';
//import oakman from '../images/PropertyPhotos/Oakman/AngleSidewalk.jpg';
//import springLakeVillage from '../images/PropertyPhotos/SpringLakeVillage/Closeup.jpg';
//import washingtonBlvd from '../images/PropertyPhotos/WashingtonBlvd/StreetView.jpg';
//import springHill from '../images/PropertyPhotos/SpringHill.jpg';
//import midviewCrossings from '../images/PropertyPhotos/midviewCrossings.jpg';

interface IProps {
    properties: PropertyModel[];
    totalProperties: number | null;
    totalStates: number | null;
}

interface IState {
    showInfo: boolean;
    clickedProperty?: PropertyModel;
}

interface PropertyModel {
    Name: string;
    Location: string;
    Units: number;
    Description: string;
    Thumbnail: string;
    Picture: string;
    SortOrder?: null | number;
}

class Properties extends React.Component<IProps, IState>{

    state: IState = {
        //properties: [
        //	{ name: 'BayView Tower', photo: bayviewTower, location: 'Muskegon, MI', units: '201'},
        //	{ name: 'Crystal Lofts',  photo: crystalLofts, location: 'Detroit, MI', units: '17' },
        //	{ name: 'Freedom Place', photo: freedomPlace, location: 'Detroit, MI', units: '352' },
        //	{ name: 'Oakman Townhomes', photo: oakman, location: 'Detroit, MI', units: '72' },
        //	{ name: 'Himelhoch', photo: himelhoch, location: 'Detroit, MI', units: '72' },
        //	{ name: 'Spring Lake Village', photo: springLakeVillage, location: 'Pontiac, MI', units: '215' },
        //	{ name: 'Washington Blvd', photo: washingtonBlvd, location: 'Detroit, MI', units: '115' },
        //	{ name: 'Spring Hill', photo: springHill, location: 'Akron, OH', units: '279' },
        //	{ name: 'Midview Crossings', photo: midviewCrossings, location: 'Elyria, OH', units: '138' },
        //],
        showInfo: false,
        clickedProperty: undefined
    }

    scrollAnchorRef: React.RefObject<HTMLDivElement> = React.createRef();

    clickedPropertyHandler = (object: PropertyModel) => {
        this.setState({ showInfo: true, clickedProperty: object });
        const anchor = this.scrollAnchorRef;
        if (anchor.current) {
            anchor.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
        else {
            return
        }
    }
    closeHandler = () => {
        this.setState({ showInfo: false });

    }

    render() {
        return (
            <section className="parent">
                <Jumbotron image={landing} preimage={smallpic} title="Our Properties" />
                <section className={classes.mainText}>
                    <h1>Taking Pride in Our Community</h1>
                    <p>American Community Developers owns {this.props.totalProperties} residential properties in {this.props.totalStates} states.  Here are just a few:</p>
                </section>
                <div ref={this.scrollAnchorRef} className="parent">
                    {this.state.showInfo && this.state.clickedProperty !== undefined ?
                        <PropertyInfo photo={this.state.clickedProperty.Picture} location={this.state.clickedProperty.Location}
                            units={this.state.clickedProperty.Units} clicked={() => this.closeHandler()} /> : null
                    }
                </div>
                <section className={classes.properties}>
                    {this.props.properties.map((property, index) => {
                        return <Property name={property.Name} key={index} photo={property.Thumbnail} clicked={() => this.clickedPropertyHandler(property)} />
                    })}
                </section>
                <Footer />
            </section>
        )

    }
}

export default Properties;


