import * as React from 'react';
import classes from '../Square/Square.module.css';
import { NavLink } from 'react-router-dom';

interface IProps {
	image: string;
	title: string;
	anchor?: string;
	action?: string;
}

const Square = (props: IProps) => {
	const style = {
		backgroundImage: "url(" + props.image + ")"
	}
	return (

	
		<section className={classes.box} style={style}>
			<h1>{props.title}</h1>
			{props.anchor ? <NavLink to={props.anchor}>{props.action ? props.action : "Learn More"}</NavLink> : ""}
		</section>
	
		);

};


export default Square;