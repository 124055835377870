import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import Footer from '../components/Footer/Footer';


import Partner from '../components/Partner/Partner';
import landing from '../images/our-partner-landing.jpg';
import smallpic from '../images/our-partner-landing-sm.jpg';

import classes from '../Stylesheets/partners.module.css';
import centerline from '../images/partner-logos/centerline.gif';
import city from '../images/partner-logos/crea.png';
import comerica from '../images/partner-logos/Comerica.gif';
import franklin from '../images/partner-logos/FranklinCapitalGroup.gif';
import PCG from '../images/partner-logos/pillar_capital.jpg';
import PNC from '../images/partner-logos/PNC.png';
import OCCH from '../images/partner-logos/Logo_OCCH.jpg';
import WNC from '../images/partner-logos/WNCCompanies.jpg';
import stratford from '../images/partner-logos/stratford.gif';
import stjames from '../images/partner-logos/St.JamesCapital.gif';
import richman from '../images/partner-logos/RichmanGroup.gif';
import raymondjames from '../images/partner-logos/RaymondJames.gif';
import IMS from '../images/partner-logos/IMS-Logo.gif';
import keybank from '../images/partner-logos/Logo_KeyBank.gif';
import MMA from '../images/partner-logos/MMAFinancial.gif';
import glcapitalfund from '../images/partner-logos/GreatLakesCapital.gif';
import PGIM from '../images/partner-logos/PGIM.jpg';
import berkadia from '../images/partner-logos/Berkadia.png';
import loveFunding from '../images/partner-logos/love-funding2-edit.jpg';
import TextBox from '../components/TextBox/TextBox';

interface IState {
	partners: { name: string, image: string }[];
}

class Partners extends React.Component<{}, IState>{


state = {
			partners: [
				{ name: 'City Real Estate Advisors', image: city },
				{ name: 'Stratford Capital Group', image: stratford },
				{ name: 'Comerica', image: comerica },
				{ name: 'Independent Management Services', image: IMS},
				{ name: 'Ohio Capital Corporation For Housing', image: OCCH },
				{ name: 'PNC', image: PNC },
				{ name: 'Berkadia', image: berkadia },
				{ name: 'Love Funding', image: loveFunding },
				{ name: 'PGIM Investments', image: PGIM },
				{ name: 'Great Lakes Capital Fund', image: glcapitalfund },
				{ name: 'Pillar Capital Group Real Estate Development', image: PCG },
				{ name: 'Key Bank', image: keybank },
				{ name: 'The Richman Group of Companies', image: richman },
				{ name: 'Centerline Capital Group', image: centerline},
				{ name: 'Franklin Capital Group', image: franklin },
				{ name: 'Raymond James Tax Credit Funds, Inc.', image: raymondjames },
				{ name: 'WNC Companies', image: WNC },
				{ name: 'MMA Financial', image: MMA },
				{ name: 'St. James Capital, L.L.C.', image: stjames}
			]

		}
	
	render() {
		return (
			<section className="parent">
				<Jumbotron image={landing} preimage={smallpic} title="Our Partners" className={classes.jumbotron} />
				<TextBox content="We are proud of the relationships we have with all our financial partners.  We have worked hard to develop these relationships and are privileged to count the industry’s leading financial companies as part of our team."/>
				<section className={classes.body}>
					{this.state.partners.map((partner, index) => {
						return (<Partner image={partner.image} key={index} />)	
					})}
				</section>
				<Footer />
			</section>		
			)
	}
};

export default Partners;