import * as React from 'react';
import classes from '../TeamMember/TeamMember.module.css';

interface IProps {
	clicked: () => void;
	photo: string;
	name: string;
	position: string;
}

const TeamMember = (props: IProps) => {
	
	return (
		<button className={classes.teamMember} onClick={props.clicked}>
			<div className={classes.employee}>
				<img src={props.photo} onMouseOver={e => (e.currentTarget.src = props.photo)} onMouseOut={e => (e.currentTarget.src = props.photo)} alt="" />
			</div>
			<section className={classes.textCard}>
				<p className={classes.name}>{props.name}</p>
				<p>{props.position}</p>
			</section>
		</button>
		);
};

export default TeamMember;


