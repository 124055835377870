import * as React from 'react';
import Navigation from '../components/Navigation/Navigation';
import ImageText from '../components/ImageText/ImageText';
import TextBox from '../components/TextBox/TextBox';
import Square from '../components/Square/Square';
import ControlledCarousel from '../components/ControlledCarousel/ControlledCarousel';
import Footer from '../components/Footer/Footer';


import classes from '../Stylesheets/home.module.css';
import box1 from '../images/home-box1.jpg';
import box2 from '../images/home-box2.jpg';
import picture1 from '../images/PropertyPhotos/SpringLakeVillage/AngleV1.jpg';
import picture2 from '../images/PropertyPhotos/Himelhoch/AngleViewVert.jpg';
import picture3 from '../images/Grey-Ghost-Best.jpg';


interface IProps {
	totalUnits: null | number,
	totalProperties: null | number,
	totalStates: null | number,
	totalInvested: null | number
}
const Home = (props: IProps) => (
		<section className="parent">
			<Navigation />
			<ControlledCarousel />

			<section className={classes.statsBar}>
				<section className={classes.stat}>
					<p className={classes.number}>{ props.totalStates }</p>
					<p>states</p>
				</section>
				<section className={classes.stat}>
					<p className={classes.number}>{ props.totalProperties }</p>
					<p>properties</p>
				</section>
				<section className={classes.stat}>
					<p className={classes.number}>{ props.totalUnits && props.totalUnits.toLocaleString() }</p>
					<p>units</p>
				</section>
				<section className={classes.stat} style={{ border: 'none' }}>
				<p className={classes.number}>$ { props.totalInvested && props.totalInvested.toFixed(1) } <span className={classes.mobile}>B</span><span className={classes.tablet}>billion</span></p>
					<p>invested</p>
				</section>
			</section>
			<section className="squares">
				<Square image={box1} title="#1 Most Affordable Housing in Michigan"/>
				<Square image={box2} title="Sustainable Communities" anchor="/green"/>
			</section>
			<TextBox
				header="Our Vision"
				content="To create and preserve housing opportunities for everyone."
			/>
			<ImageText
				image={picture1}
				header="Affordable & Workforce Housing"
				content="ACD is committed to creating and preserving affordable housing in communities across the U.S.  The range of affordability that we target extends from those within our communities who have the greatest needs, to those who face rapidly-rising housing costs and need workforce housing options.  We have extensive experience with traditional affordable housing programs and continue to pursue new programs and tools designed to keep housing attainable for everyone."
			/>
		<ImageText
				opposite
				image={picture2}
				header="Market Rate Housing"
				content="ACD offers modern and sustainable market-rate housing with a growing range of options.  As with our housing developments, we strive to achieve the highest quality while minimizing our impact on the environment."
			/>
		<ImageText
			image={picture3}
			header="Commercial Buildings"
			content="ACD proudly owns 60,000 square feet of commercial space across the Woodward Corridor in the Central Business District, Midtown, and New Center. We take a neighborhood-first approach when selecting commercial tenants, evaluating how the business fits within the context of what’s happening around it.  Our goal is to contribute to vibrant and walkable districts that add value to the surrounding community.
"
		/>

		<Footer />
		</section>

);

export default Home;
