import * as React from 'react';
import classes from '../Partner/Partner.module.css';

interface IProps {
	image: string;
}

const Partner = (props: IProps) => {
	const style = {
		backgroundImage: "url("+props.image+")"
	}
	return (
		<section className={classes.partner}>
			<section className={classes.image} style={style}>

			</section>
		</section>

	)

}

export default Partner;