import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import About from './pages/About';
import Properties from './pages/Properties';
import Team from './pages/Team';
//import News from './pages/News';
import Careers from './pages/Careers';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import Green from './pages/Green';
//import axios from 'axios';
import _ from 'underscore';



export interface PeopleResponse {
    People: PeopleEntity[];
}

export interface PeopleEntity {
    Name: string;
    Title: string;
    Description: string;
    ImageSprite: string;
    ImageZoom: string;
}

interface IState {
    FeaturedProperties: Array<{ Name: string, Location: string, Units: number, Description: string, Thumbnail: string, Picture: string, SortOrder: null | number }>,
    TotalUnits: null | number,
    TotalProperties: null | number,
    TotalStates: null | number,
    TotalInvested: null | number,
    PeopleEntities?: PeopleEntity[] | null;
}

function api<T>(url: string): Promise<T> {
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                const errorMessage = 'Error loading data from ' + url + ': ' + response.status + ': ' + response.statusText;
                console.log(errorMessage);
                return Promise.reject(errorMessage);
            }
            return response.json().then(data => data as T);
        });
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
function shuffleArray(array: any[]) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

class App extends React.Component<{}, IState> {
    state: IState = {
        FeaturedProperties: [],
        TotalUnits: null,
        TotalProperties: null,
        TotalStates: null,
        TotalInvested: null,
        PeopleEntities: null
    }
    componentDidMount() {
        api<PeopleResponse>('https://acd1.blob.core.windows.net/employeephotos/index.json').then(data => {
            var people = data.People;
            shuffleArray(people);
            this.setState({ PeopleEntities: people });
        });
        api<IState>('https://www.acdmail.com/propfacts/Home/ReactSiteInfo').then(data => {
			var properties = data.FeaturedProperties;
			properties.map((property) => {
				if (property.SortOrder === null) {
					property.SortOrder = Math.floor(Math.random() * (2000 - 1000) + 1000);
				}
			});
			properties = _.sortBy(properties, p => p.SortOrder);
			data.FeaturedProperties = properties;
			this.setState(data);
        });
    }
    render() {
        return (
            <BrowserRouter>
                <div className="App">

                    <Switch>
						<Route path="/about" component={About} />
                        <Route path="/properties" render={() => <Properties properties={this.state.FeaturedProperties} totalProperties={this.state.TotalProperties} totalStates={this.state.TotalStates} />} />
                        <Route path="/team" render={() => <Team peopleEntities={this.state.PeopleEntities} />} />
                        <Route path="/careers" component={Careers} />
                        <Route path="/partners" component={Partners} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/green" component={Green} />
                        <Route path="/" render={() => <Home totalUnits={this.state.TotalUnits} totalProperties={this.state.TotalProperties} totalStates={this.state.TotalStates} totalInvested={this.state.TotalInvested} />} />
                    </Switch>

                </div>
            </BrowserRouter>
        );
    }
}

export default App;
