import * as React from 'react';
import Navigation from '../../components/Navigation/Navigation';

import IronImage from '../../components/IronImage/IronImage';
import classes from '../Jumbotron/Jumbotron.module.css';

interface IProps {
	preimage: string;
	image: string;
	title: string;
	className?: string;
}
const Jumbotron = (props: IProps) => {	
	return (
		<section className={classes.jumbotron}>
			<Navigation />
			{props.className ? 
				<IronImage placeholder={props.preimage} src={props.image} title={props.title} className={props.className} />
				:
				<IronImage placeholder={props.preimage} src={props.image} title={props.title} />
}
		</section>
		
		);
}

export default Jumbotron;
