import * as React from 'react';
import classes from '../PropertyInfo/PropertyInfo.module.css';

interface IProps {
	clicked: () => void;
	photo: string;
	info?: string;
	location: string;
	units: number;
}
const PropertyInfo = (props: IProps) => {
	return (
		<div className={classes.PropertyInfo}>
			<section className={classes.icon} onClick={props.clicked}>
				<i className="fas fa-times"></i>
			</section>
			<section className={`${classes.image} ${classes.clearfix}`}>
				<img src={props.photo} alt="" />
			</section>
			<section className={classes.info}>
							<p>Location: {props.location}</p>
							<p>Units: {props.units}</p>
						</section>
			
		</div>
	);
};

export default PropertyInfo;

