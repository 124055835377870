import React from 'react';
import classes from './Footer.module.css';
import { NavLink } from 'react-router-dom';


const Footer = () => {	
	return (
		<div className={classes.footer}>
			<section className={classes.address}>
				<p>Contact</p>
				<p>20250 Harper Ave.</p>
				<p>Detroit, MI 48225</p>
				<p>313-881-8150</p>
			</section>
			<section className={classes.links}>
				<p>Site Links</p>
				<section className={classes.linkWrapper}>
					<section className={classes.linkPair}>
						<NavLink to="/about">About</NavLink>
						<NavLink to="/properties">Properties</NavLink>
					</section>
					<section className={classes.linkPair}>
					    <NavLink to="/team">Team</NavLink>
						<NavLink to="/careers">Careers</NavLink>
					</section>
					<section className={classes.linkPair}>
						<NavLink to="/partners">Partners</NavLink>
						<a href="http://download.acdmail.com/6">Downloads</a>
					</section>
				</section>
			</section>
		
			<section className={classes.copyright}>
				<p>© Copyright 2019 American Community Developers, Inc.  All Rights Reserved. </p>
			</section>
		

		</div>
		
		
		
		)

} 

export default Footer;
