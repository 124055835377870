import * as React from 'react';
import classes from '../ImageText/ImageText.module.css';

interface IProps {
	image: string;
	opposite?: boolean,
	header: string,
	content: string,
}

const ImageText = (props: IProps) => {

	let style = null;
		style = {
			backgroundImage: "url(" + props.image + ")"
		}


	return (
		<section className={classes.imageText}>
			<section className={`${classes.picture} ${props.opposite ? classes.opposite : ''}`} style={style}>
				<h2>{props.header}</h2>
			</section>
			<section className={classes.paragraph}>
				<h2>{props.header}</h2>
				<p>{props.content}</p>
			</section>
			</section>

		
		)


}


export default ImageText;

//if (props.opposite && window.innerWidth > 1000) {
//	style = {
//		backgroundImage: "url(" + props.image + ")",
//		order: 1
//	}