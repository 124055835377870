import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import ImageText from '../components/ImageText/ImageText';
import TextBox from '../components/TextBox/TextBox';
import Square from '../components/Square/Square';
import Footer from '../components/Footer/Footer';

//import classes from '../Stylesheets/about.module.css';
import landing from '../images/about-landing.jpg';
import smallpic from '../images/about-landing-sm.jpg';
import box1 from '../images/about-box1.jpg';
import box2 from '../images/about-box2.jpg';
import picture1 from '../images/about-picture1.jpg';
import picture2 from '../images/about-picture2.jpg';


const About = () => (
		<section className="parent">
		<Jumbotron image={landing} preimage={smallpic} title="About"/>
		   <TextBox
				header="Our Mission"
			content="ACD’s mission is to create and preserve market rate and affordable housing that is high-quality and sustainable."
			/>
			<section className="squares">
				<Square image={box1} title="Partners" anchor="/partners"/>
				<Square image={box2} title="Leadership" anchor="/team" />
			</section>
		<ImageText
				image={picture1}
				header="Our History"
				content="ACD was founded in 1980 by Gerald A. Krueger primarily for the acquisition and rehabilitation of existing affordable housing developments.  Over the years, we have broadened our scope of experience to include new construction, adaptive re-use, and historic renovations that consist of affordable, workforce and market rate housing.  Our team of real estate professionals brings together a mix of diverse backgrounds and disciplines that continues to propel the company’s success and growth in an ever-changing development landscape.  ACD is also the parent organization to St. Clair Construction Company, a general contractor that specializes in apartment rehabilitation. "
			/>
		<ImageText
				opposite
				image={picture2}
				header="Continued Commitments"
			content=" ACD is recognized nationally as a leader in the housing industry.  We continue to remain committed to affordable and workforce housing, energy conservation and sustainable developments that minimize our environmental impact."
		/>
		<Footer/>
		</section>
);

export default About;