import * as React from 'react';
import { NavLink } from 'react-router-dom';

import classes from '../Navigation/Navigation.module.css';
import acdlogo from '../../images/ACD.svg';
import acd from '../../images/AmericanCommunityDevelopers.svg';

interface IState {
	collapsed: boolean;
}

class Navigation extends React.Component<{},IState> {

		state = {
		collapsed: true
		}
	
	

	toggleNavbar = () => {
		window.setTimeout(() => { this.setState({ collapsed: !this.state.collapsed }) }, 500);

		//if (!this.state.collapsed) {
		//	//hide with delay for collapsed =true but hidemenu = true right away
		//	//window.setTimeout()
		//	//old code
		//	console.log("I AM A DELAY")
		//	window.setTimeout(() => { this.setState({ collapsed: !this.state.collapsed }) }, 500);
		//}
		//else {
		//	console.log("I AM NOT")
		//	this.setState({ collapsed: !this.state.collapsed })
		//}
}
	render() {
		//let style = null;
		//if (window.innerWidth > 768) {
		//	 style = { fontWeight: 'bold', color: '#072656' }
		//}
		//console.log(this.state.collapsed);
		return (
			<div className={classes.Navigation} >
				<p className={classes.AcdLogo}><NavLink exact to="/"><img src={acdlogo} alt='ACD logo' /><img src={acd} alt="American Commmunity Developers" /></NavLink></p>
				<div className={this.state.collapsed ? "": classes.collapsed} >
					<i id="hamburger" className="fas fa-bars" onClick={this.toggleNavbar}></i>
				</div>
				<div className={this.state.collapsed ? classes.collapsed : ""}>
					<ul className={`${classes.menu} ${this.state.collapsed ? classes.hidemenu : ''}`}>
						<li><i className="fas fa-times" onClick={this.toggleNavbar}></i></li>
						<li><NavLink to="/about" activeClassName={classes.active}>ABOUT</NavLink></li>
						<li><NavLink to="/properties" activeClassName={classes.active} >PROPERTIES</NavLink></li>
						<li><NavLink to="/team" activeClassName={classes.active}>TEAM</NavLink></li>
						<li><NavLink to="/careers" activeClassName={classes.active}>CAREERS</NavLink></li>
						<li><NavLink to="/partners" activeClassName={classes.active}>PARTNERS</NavLink></li>
						<li><NavLink to="/green" activeClassName={classes.active}>GREEN</NavLink></li>
						<li><NavLink to="/contact" activeClassName={classes.active}>CONTACT</NavLink></li>
						<li><a href="http://download.acdmail.com/6">DOWNLOADS</a></li>
					</ul>
				</div>
			</div>
		)



	}

};

export default Navigation;