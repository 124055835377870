import * as React from 'react';
import classes from '../Property/Property.module.css';

interface IProps {
	clicked: () => void;
	photo: string;
	name: string;
}

const Property = (props: IProps) => {
	return (
		<button className={classes.property} onClick={props.clicked}>
			<img src={props.photo} alt=""/>
			<section className={classes.propertyName}>
				<p>{props.name}</p>
			</section>
		</button>

		
		)
}

export default Property;

