import * as React from 'react';
import classes from '../TextBox/TextBox.module.css';

interface IProps {
	header?: string;
	content?: string;
	children?: any;
}

const TextBox = (props: IProps) => {

	return (
		<section className={classes.textBox}>
            { props.header ? <h1>{props.header}</h1> : null }
			{ props.content ? <p>{props.content}</p> : null }
            { props.children }
		</section>
	);


}


export default TextBox;