import * as React from 'react';
import classes from '../BioBox/BioBox.module.css';

interface IProps {
	clicked: () => void;
	photo: string,
	name: string,
	bio: string,
	position: string,
}
const BioBox = (props: IProps) => {
	return (
		<div className={classes.bioBox}>
			<section className={classes.icon} onClick={props.clicked}>
				<i className="fas fa-times"></i>
			</section>
			<section className={`${classes.image} ${classes.clearfix}`}>
				<img src={props.photo} alt="" />
			</section>
				<section className={classes.textArea}>
					<section className={classes.label}>
						<h4>{props.name}</h4>
						<p>{props.position}</p>
					</section>
					<section className={classes.textBox}>
					<p>{props.bio}</p>
						</section>
					</section>
	
		</div>
		);
};

export default BioBox;
