import * as React from 'react';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import Footer from '../components/Footer/Footer';


import classes from '../Stylesheets/contact.module.css';
import landing from '../images/contact-landing.jpg';
import smallpic from '../images/contact-landing-sm.jpg';
import acdlogo from '../images/ACD.svg';
import acd from '../images/AmericanCommunityDevelopers.svg';
import scclogo from '../images/SCC.svg';
import scc from '../images/StClairConstruction.svg';


const Contact = () => (
	<section className="parent">
			<Jumbotron image={landing} preimage={smallpic} title="Contact" className={classes.jumbotron} />
			<section className="subheader">
				<h1>We would love to connect with you.</h1>
			</section>
		<section className={classes.container}>
			<section className={classes.businessCard}>
				<section className={classes.name}>
					<p><img src={acdlogo} alt='ACD logo' /></p>
					<p><img src={acd} alt='American Community Developers' /></p>
				</section>
				<section className={classes.address}>
					<p>20250 Harper Ave.</p>
					<p>Detroit, MI 48225</p>
					<p>313-881-8150</p>
					<p>info@acdmail.com</p>
			</section>
		</section>
		<section className={classes.businessCard}>
			<section className={classes.name}>
					<p><img src={scclogo} alt='SCC logo' /></p>
					<p><img id={classes.special} src={scc} alt='St Clair Construction' /></p>
			</section>
			<section className={classes.address}>
				<p>20250 Harper Ave.</p>
				<p>Detroit, MI 48225</p>
				<p>313-432-7860</p>
				<p>info@acdmail.com</p>
			</section>
		</section>
		</section>
		<Footer />
		</section>
);

export default Contact;