import * as React from 'react';
import classes from '../Stylesheets/green.module.css';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import ImageText from '../components/ImageText/ImageText';
import TextBox from '../components/TextBox/TextBox';
import Square from '../components/Square/Square';
import Footer from '../components/Footer/Footer';

import landing from '../images/green-landing.jpg';
import smallpic from '../images/green-landing-sm.jpg';
import picture1 from '../images/PropertyPhotos/SpringLakeVillage/Aerial1.jpg';
import picture2 from '../images/HR.jpg';
import box1 from '../images/green-box1.jpg';
import box2 from '../images/green-box2.jpg';
import { NavLink } from 'react-router-dom';



const Green = () => (
	<React.Fragment>
			<section className="parent" >
			<Jumbotron image={landing} preimage={smallpic} title="Sustainable Communities" className={classes.jumbotron} />
			<section className="subheader">
				<h1>Caring for Our World and the People in it</h1>
			</section>
			<section className="squares">
				<Square image={box1} title="Green Communities" />
				<Square image={box2} title="Healthy Lifestyles" />
			</section>
		<TextBox 
			content="Our mission is to “create and preserve high-quality housing that is affordable and sustainable.”  To make our housing sustainable, we’re committed to creating safe living environments that set up families for success in the long-term.  We strategically select homes in walkable neighborhoods near jobs, public transit, and services that help provide opportunities and conserve family income.  We’ve also carefully reviewed our building practices to ensure resident health; we take steps to provide proper ventilation, prevent pest infestation, and avoid chemical contaminants, among other things." 
				/>
		<ImageText
						image={picture1}
						header="Green Communities Certified" 
						content="We strive to achieve energy efficiency certification at all our communities through programs like Enterprise Green Communities, LEED and Energy Star.  These programs focus on using durable materials from renewable resources as well as reducing electric and water usage and controlling storm water runoff. "
		/>
		<ImageText
						opposite
						image={picture2}
						header="Recycling" 
						content="One of our subsidiaries, Hamtramck Recycling, is a recycling operation we created to continue our commitment to use recyclable materials and protect the environment.  We give companies of all sizes a realistic ability to become landfill-free by providing recycling options for construction and industrial debris.  Housing should be sustainable for families and sustainable for the planet!"
			/>
			<TextBox><p className={classes.viewProperties}><NavLink to="/properties">View our properties &nbsp; &gt;</NavLink></p></TextBox> 
		<Footer />
		</section>
	</React.Fragment>
);

export default Green;					

