import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';
//import Navigation from '../../components/Navigation/Navigation';
import  '../ControlledCarousel/ControlledCarousel.css';
import slide1 from '../../images/affordable-housing-landing.jpg';
import slide2 from '../../images/market-rate-housing-landing.jpeg';
import slide3 from '../../images/environmentally-conscious-landing.jpg';


interface IState {
	index: number;
	direction?: string;
}

class ControlledCarousel extends React.Component<{},IState> {

		state: IState = {
			index: 0,

		};
	
	//get rid of temporary any
	handleSelect = (selectedIndex: number, e:any) => {
		this.setState({
			index: selectedIndex,
			direction: e.direction,
		});
	}

	render() {
		const { index } = this.state;
		let style3 = null;
		if (window.innerWidth > 1500) {
			style3 = {
				backgroundImage: "url(" + slide3 + ")",
				backgroundPosition: 'top'
			}
		} else {
			style3 = {
				backgroundImage: "url(" + slide3 + ")",
				backgroundPosition: 'center'
			}
		}
	
	
		return (
			<Carousel
				activeIndex={index}
				onSelect={this.handleSelect}
			>
			
				<Carousel.Item style={{ backgroundImage: "url(" + slide1 + ")" }}>
					<Carousel.Caption>
						<h3>Affordable Housing</h3>
					</Carousel.Caption>
				</Carousel.Item>

				<Carousel.Item style={{ backgroundImage: "url(" + slide2 + ")" }}>
					<Carousel.Caption>
						<h3>Market Rate Housing</h3>
					</Carousel.Caption>
				</Carousel.Item>

				<Carousel.Item style={style3}>
					<Carousel.Caption>
						<h3>Environmentally Conscious</h3>
					</Carousel.Caption>
				</Carousel.Item>

			</Carousel>
		);
	}
}

export default ControlledCarousel;